import { useState } from 'react'

import { AccordionCustom } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { TagEventsEnum } from 'enums/TagEvents'
import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { IPetAfterSales } from 'store/afterSales/types'
import { Icons } from 'utils/icons'

import { Button } from '@interco/inter-ui/components/Button'
import { Text } from '@interco/inter-ui/components/Text'
import { navigate } from '@reach/router'

import * as S from '../../Common/Common.styles'
import { AnalyticsAfterSalesDetails } from '../AfterSalesDetails.tags'

interface IAccordionsPetDetailsProps {
  urlPdfGracePeriods: string
  afterSalesPet: IPetAfterSales
  hideCancelationAccordion: boolean
}

export const AccordionsPetDetails = ({
  urlPdfGracePeriods,
  afterSalesPet,
  hideCancelationAccordion,
}: IAccordionsPetDetailsProps) => {
  const [expandedAccordions, setExpandedAccordions] = useState<number[]>([])
  const handlerError = useLocalErrorHandler()

  const accordionIcons = {
    handUser: <Icons.HandUser width={24} height={24} color="var(--gray500)" />,
    canceled: <Icons.Canceled width={24} height={24} color="var(--gray500)" />,
  }

  const isExpanded = (key: number) => expandedAccordions.includes(key)

  const openWaitingPeriodPdf = async () => {
    try {
      await BaseBridge.openPdf(urlPdfGracePeriods, 'Coparticipação e Carência')
    } catch (error) {
      handlerError(error as Error, 'AccordionsPetDetails.openWaitingPeriodPdf')
    }
  }

  const goToCancelation = () => {
    AnalyticsAfterSalesDetails(TagEventsEnum.PET_FLOW)

    const mktCancellationUrl = `${process.env.REACT_APP_MKTPL_CANCELLATION_URL}/?subscriptionId=${afterSalesPet.id}`
    navigate(mktCancellationUrl)
  }

  const handleAccordions = (key: number) => {
    isExpanded(key)
      ? setExpandedAccordions([...expandedAccordions.filter((x) => x !== key)])
      : setExpandedAccordions([...expandedAccordions, key])
  }

  return (
    <S.AccordionSection>
      <AccordionCustom
        key="1"
        icon={accordionIcons.handUser}
        title="Carência"
        expanded={isExpanded(1)}
        onClick={() => handleAccordions(1)}
      >
        <Text variant="caption-1" as="p">
          Consultas simples, vacinas e outros procedimentos válidos a partir de 45 dias, e exames
          laboratoriais após 60 dias da contratação.{' '}
        </Text>
        <Button variant="link" size="default" onClick={openWaitingPeriodPdf}>
          Carência de serviços
        </Button>
      </AccordionCustom>

      {!hideCancelationAccordion && (
        <AccordionCustom
          key="3"
          icon={accordionIcons.canceled}
          title="Cancelamento"
          expanded={isExpanded(3)}
          onClick={() => handleAccordions(3)}
        >
          <Text variant="caption-1" as="p">
            Ao cancelar o plano, o seu pet perderá acesso aos procedimentos veterinários essenciais
            à sua saúde.
          </Text>
          <Button variant="link" size="default" onClick={goToCancelation}>
            Cancelar o plano do meu pet
          </Button>
        </AccordionCustom>
      )}
    </S.AccordionSection>
  )
}
