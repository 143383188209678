import { useEffect } from 'react'

import { ListDescription, Separator } from 'components'
import BaseBridge from 'config/bridge/BaseBridge'
import { ActionIconEnum } from 'enums/ActionIcon'
import { PageTitles } from 'enums/PageTitles'
import { PetTypeEnum } from 'enums/PetType'
import { TagEventsEnum } from 'enums/TagEvents'
import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { useNavBar } from 'hooks/useNavBar'
import { ShimmerHome } from 'pages/home/Home/ShimmerHome/ShimmerHome'
import { useHistory } from 'react-router-dom'
import { TypesRoutes } from 'routes/mixedRoutes/types'
import useBoundState from 'store'
import { IQuickAccessBar } from 'types/IAfterSales'
import { Icons } from 'utils/icons'

import { uuid } from '@interco/inter-ui'
import { Babi } from '@interco/inter-ui/components/Babi'
import { Button } from '@interco/inter-ui/components/Button'
import { Flex } from '@interco/inter-ui/components/Flex'
import { Text } from '@interco/inter-ui/components/Text'

import { Accordions } from './Accordions/Accordions'
import * as S from './AfterSales.styles'

import { AfterSalesStatusTag } from '..'

import { AnalyticsAfterSales } from './AfterSales.tags'

export const AfterSales = () => {
  const history = useHistory()
  const handlerError = useLocalErrorHandler()

  const isLoading = useBoundState((state) => state.isLoading)
  const afterSales = useBoundState((state) => state.afterSales)
  const getLandingPage = useBoundState((state) => state.getLandingPage)
  const setPet = useBoundState((state) => state.setAfterSalesPet)
  const getCoveredClinicsAfterSales = useBoundState((state) => state.getCoveredClinicsAfterSales)

  useNavBar({
    navbarTitle: PageTitles.PET,
    actionIcons: {
      leftIcon: ActionIconEnum.BACK,
    },
  })

  useEffect(() => {
    AnalyticsAfterSales(TagEventsEnum.PET_FLOW)
  }, [])

  const petIcons = {
    dog: <Icons.Dog width={24} height={24} color="var(--primary500)" />,
    cat: <Icons.Cat width={24} height={24} color="var(--primary500)" />,
    chevronRight: <Icons.ChevronRight width={24} height={24} color="var(--primary500)" />,
  }

  const quickAccessIcons = {
    add: <Icons.Add width={24} height={24} color="var(--primary500)" />,
    location: <Icons.Location width={24} height={24} color="var(--primary500)" />,
    pdf: <Icons.Pdf width={24} height={24} color="var(--primary500)" />,
    handUser: <Icons.HandUser width={24} height={24} color="var(--primary500)" />,
  }

  const fetchCoveredClinicsResponse = () => {
    getCoveredClinicsAfterSales({ history, pathname: TypesRoutes.PARTNER_CLINICS })
  }

  const openProductManual = async () => {
    try {
      await BaseBridge.openPdf(afterSales.productManual, 'Manual do produto')
    } catch (error) {
      handlerError(error as Error, 'AfterSales.openProductManual')
    }
  }

  const openWaitingPeriodPdf = () => {
    const openPdfPromise = BaseBridge.openPdf(
      afterSales.urlPdfGracePeriods,
      'Coparticipação e Carência',
    )
    openPdfPromise.catch((error) => {
      handlerError(error as Error, 'AfterSales.openWaitingPeriodPdf')
    })
  }

  const quickAccessBar: IQuickAccessBar[] = [
    {
      icon: quickAccessIcons.add,
      description: 'Adicionar outro Pet',
      route: () => {
        getLandingPage({
          history,
          pathname: TypesRoutes.HOME,
        })
      },
    },
    {
      icon: quickAccessIcons.handUser,
      description: 'Carência de serviços',
      route: openWaitingPeriodPdf,
    },
    {
      icon: quickAccessIcons.location,
      description: 'Rede credenciada',
      route: fetchCoveredClinicsResponse,
    },
    {
      icon: quickAccessIcons.pdf,
      description: 'Manual do produto',
      route: openProductManual,
    },
  ]

  const renderMyPets = afterSales.pets?.map((pet) => {
    const tagCancelDate = pet.cancelDate
    return (
      <ListDescription
        key={pet.id}
        iconLeft={pet.type === PetTypeEnum.DOG ? petIcons.dog : petIcons.cat}
        iconRight={petIcons.chevronRight}
        withBorder
        margin="16px 0"
        onClick={() => {
          setPet(pet)
          history.push(TypesRoutes.AFTER_SALES_DETAILS)
        }}
      >
        <Flex direction="column">
          <Text variant="body-2" color="var(--gray500)" bold>
            {pet.name}
          </Text>
          <AfterSalesStatusTag status={pet.status} cancelDate={tagCancelDate} />
        </Flex>
      </ListDescription>
    )
  })

  const renderQuickAccess = quickAccessBar.map((item) => (
    <Button key={uuid()} variant="link" onClick={item.route}>
      <S.QuickAccessButton>{item.icon}</S.QuickAccessButton>
      <Text variant="caption-2" bold colorWeight={500}>
        {item.description}
      </Text>
    </Button>
  ))

  return isLoading ? (
    <ShimmerHome />
  ) : (
    <S.Container>
      <S.MyPets>{renderMyPets}</S.MyPets>
      <S.QuickAccess>{renderQuickAccess}</S.QuickAccess>
      <Separator margin="32px 0" />
      <Accordions />
      <Separator margin="32px 0" />
      <S.Footer>
        <Babi origin={TagEventsEnum.PRODUCT} />
      </S.Footer>
    </S.Container>
  )
}
