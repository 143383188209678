import Add from '@interco/icons/core/action-navigation/ic_add'
import Apps from '@interco/icons/core/action-navigation/ic_apps'
import ArrowLeft from '@interco/icons/core/action-navigation/ic_arrow_left'
import Schedule from '@interco/icons/core/action-navigation/ic_calendar'
import Check from '@interco/icons/core/action-navigation/ic_check'
import ChevronDown from '@interco/icons/core/action-navigation/ic_chevron_down'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import ChevronUp from '@interco/icons/core/action-navigation/ic_chevron_up'
import Home from '@interco/icons/core/action-navigation/ic_house'
import Pdf from '@interco/icons/core/files/ic_file_pdf'
import MoneyCheck from '@interco/icons/core/finance/ic_coin_check'
import ListCheck from '@interco/icons/core/finance/ic_wallet_money'
import Manual from '@interco/icons/core/home-objects/ic_book_open'
import Cat from '@interco/icons/core/home-objects/ic_cat'
import Dog from '@interco/icons/core/home-objects/ic_dog'
import HandMoney from '@interco/icons/core/human/ic_hand_money'
import Desktop from '@interco/icons/core/media-communication/ic_desktop'
import Email from '@interco/icons/core/media-communication/ic_envelope'
import Phone from '@interco/icons/core/media-communication/ic_phone'
import CheckCircle from '@interco/icons/core/status/ic_check_circle'
import Canceled from '@interco/icons/core/status/ic_close_circle'
import Help from '@interco/icons/core/status/ic_help_circle'
import Pending from '@interco/icons/core/status/ic_info_circle_fill'
import Warning from '@interco/icons/core/status/ic_warning_circle'
import Location from '@interco/icons/core/travel-mobility/ic_location'
import HandUser from '@interco/icons/core/human/ic_hand_user'

export const Icons = {
  Add,
  Apps,
  ArrowLeft,
  Canceled,
  Desktop,
  Email,
  Pending,
  Phone,
  Check,
  CheckCircle,
  ChevronRight,
  ChevronDown,
  ChevronUp,
  Location,
  ListCheck,
  Home,
  Schedule,
  Manual,
  MoneyCheck,
  Help,
  Pdf,
  Dog,
  Cat,
  HandMoney,
  Warning,
  HandUser,
}
