import { Location, PartnerClinics } from 'pages/accredited'
import { AfterSales, AfterSalesDetails, HowUse } from 'pages/afterSales'
import { Receipt, Register } from 'pages/hiring'
import { Home, PlanDetails, Start } from 'pages/home'
import { Faq } from 'pages/shared'

import { TypesRoutes as Routes } from './types'

export const mixedRoutes = [
  // HOME
  { route: Routes.START, component: Start },
  { route: Routes.HOME, component: Home },
  { route: Routes.PLAN_DETAILS, component: PlanDetails },

  // LOCATION
  { route: Routes.LOCATION, component: Location },
  { route: Routes.PARTNER_CLINICS, component: PartnerClinics },

  // HIRING
  { route: Routes.REGISTER, component: Register },
  { route: Routes.RECEIPT, component: Receipt },

  // SHARED
  { route: Routes.FAQ, component: Faq },

  // AFTER SALES
  { route: Routes.AFTER_SALES, component: AfterSales },
  { route: Routes.AFTER_SALES_DETAILS, component: AfterSalesDetails },
  { route: Routes.HOW_USE, component: HowUse },
]

export * from './types'
