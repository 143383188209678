import styled from 'styled-components'

export const BtnLeft = styled.button.attrs({ type: 'button' })`
  justify-content: flex-start;
`

export const BtnRight = styled.button.attrs({ type: 'button' })`
  justify-content: flex-end;
`
export const ContentNavbar = styled.div<{ $hide?: boolean }>`
  display: ${(props) => (props.$hide ? 'none' : 'block')};

  nav {
    height: auto;
  }
`
