import ReceiptService from 'services/ReceiptService'
import useBoundState from 'store'
import { ErrorDetails } from 'store/error/types'
import { errorHandling } from 'store/utils/provider'
import { StateCreator } from 'zustand'

import { IReceipt, IReceiptRequest, ReceiptState } from './types'

export const createReceiptSlice: StateCreator<ReceiptState, [], [], ReceiptState> = (set) => ({
  receipt: {} as IReceipt,

  setReceipt: (receipt) => set(() => ({ receipt })),

  getReceipt: async (payload) => {
    useBoundState.getState().setIsLoading(true)
    useBoundState.getState().setHideNavbar(true)

    const { subscriptionId } = payload as IReceiptRequest

    await ReceiptService.getReceipt(subscriptionId)
      .then((response) => {
        set({ receipt: response })
      })
      .catch((error) => {
        const errorDetails: ErrorDetails = {
          title: 'Houve um erro por aqui',
          subTitle:
            'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
          disabledButton: true,
        }

        errorHandling(error as Error, 'ReceiptService.getReceipt', errorDetails)
      })
      .finally(() => {
        useBoundState.getState().setIsLoading(false)
      })
  },
})
