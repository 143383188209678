import { useEffect, useLayoutEffect } from 'react'

import { ErrorIconEnum } from 'enums/ErrorIconEnum'
import { useLocalErrorHandler } from 'hooks/useLocalErrorHandler'
import { BrowserRouter } from 'react-router-dom'
import useBoundState from 'store'

import { Theme, initTheme } from '@interco/inter-ui'

import { Navbar } from './components/Navbar/Navbar'
import BaseBridge from './config/bridge/BaseBridge'
import NewRelicUtils from './config/monitoring/NewRelicUtils'
import { ErrorView } from './pages/shared'
import Routes from './routes'

const App = () => {
  const handlerError = useLocalErrorHandler()
  const setHasError = useBoundState((state) => state.setHasError)

  const blockBrowserAccessInPrd = process.env.NODE_ENV === 'production' && BaseBridge.isBrowser()

  useLayoutEffect(() => {
    getChooseTheme()
  }, [])

  useEffect(() => {
    NewRelicUtils.setInitSettings()
  }, [])

  useEffect(() => {
    if (blockBrowserAccessInPrd) {
      setHasError({
        title: 'Baixe o Super App do Inter',
        subTitle:
          'Para acessar este produto, faça o download do Super App na sua loja de aplicativos',
        disabledButton: true,
        disabledHome: true,
        icon: ErrorIconEnum.WARNING,
      })
    }
  }, [])

  const getChooseTheme = () => {
    BaseBridge.getAppInfo()
      .then((response) => {
        const { isDarkMode } = response

        initTheme(Theme.PF, isDarkMode)
      })
      .catch((error) => handlerError(error as Error, 'BaseBridge.getAppInfo'))
  }

  return blockBrowserAccessInPrd ? (
    <ErrorView />
  ) : (
    <BrowserRouter>
      <ErrorView />
      <Navbar />
      <Routes />
    </BrowserRouter>
  )
}

export default App
